<template>
  <v-data-table
    :headers="headers"
    :items="credit_customers"
    :search="search"
    :server-items-length="total"
    :options.sync="serverOptions"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>

        <ModalAddCredit :customer_id="customer_id"></ModalAddCredit>

        <!--        <v-dialog-->
        <!--          v-model="dialogCash"-->
        <!--          max-width="500px"-->
        <!--        >-->
        <!--          <template v-slot:activator="{ on, attrs }">-->
        <!--            &lt;!&ndash; <div class="d-flex justify-space-between"> &ndash;&gt;-->
        <!--            &lt;!&ndash; <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field> &ndash;&gt;-->
        <!--            <v-btn-->
        <!--              color="warning"-->
        <!--              dark-->
        <!--              class="mb-2 mr-3"-->
        <!--              v-bind="attrs"-->
        <!--              v-on="on"-->
        <!--            >-->
        <!--              เติมเงิน-->
        <!--            </v-btn>-->
        <!--            &lt;!&ndash; </div> &ndash;&gt;-->
        <!--          </template>-->
        <!--          <v-card>-->
        <!--            <v-card-title>-->
        <!--              <span class="text-h5">{{ formCash }}</span>-->
        <!--            </v-card-title>-->

        <!--            <v-card-text>-->
        <!--              <v-container>-->
        <!--                <v-row>-->
        <!--                  <v-col-->
        <!--                    cols="12"-->
        <!--                    sm="12"-->
        <!--                    md="12"-->
        <!--                  >-->
        <!--                    <v-text-field-->
        <!--                      v-model="editedItem.numberCustomer"-->
        <!--                      label="ลูกค้า"-->
        <!--                    ></v-text-field>-->
        <!--                  </v-col>-->
        <!--                  <v-col-->
        <!--                    cols="12"-->
        <!--                    sm="12"-->
        <!--                    md="12"-->
        <!--                  >-->
        <!--                    <v-text-field-->
        <!--                      v-model="editedItem.status"-->
        <!--                      label="จำนวนเครดิต"-->
        <!--                    ></v-text-field>-->
        <!--                  </v-col>-->
        <!--                  <v-col-->
        <!--                    cols="12"-->
        <!--                    sm="12"-->
        <!--                    md="12"-->
        <!--                  >-->
        <!--                    <v-text-field-->
        <!--                      v-model="editedItem.status"-->
        <!--                      label="หมายเหตุ"-->
        <!--                    ></v-text-field>-->
        <!--                  </v-col>-->
        <!--                </v-row>-->
        <!--              </v-container>-->
        <!--            </v-card-text>-->

        <!--            <v-card-actions>-->
        <!--              <v-spacer></v-spacer>-->
        <!--              <v-btn-->
        <!--                color="error"-->
        <!--                text-->
        <!--                @click="close"-->
        <!--              >-->
        <!--                Cancel-->
        <!--              </v-btn>-->
        <!--              <v-btn-->
        <!--                color="primary"-->
        <!--                text-->
        <!--                @click="save"-->
        <!--              >-->
        <!--                Save-->
        <!--              </v-btn>-->
        <!--            </v-card-actions>-->
        <!--          </v-card>-->
        <!--        </v-dialog>-->
        <ModalAddCash :customer_id="customer_id"></ModalAddCash>

        <v-dialog
          v-model="dialogDelete"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h5 mb-5">
              Are you sure you want to delete this item?
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                outlined
                @click="closeDelete"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                @click="deleteItemConfirm"
              >
                OK
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.index="{ index }">
      {{ ((serverOptions.page-1)*serverOptions.itemsPerPage) + index +1 }}
    </template>
    <template v-slot:item.actions="{ item }">

      <router-link
        class="none-decoration"
        :to="{ name: 'pages-report-add-credit',params: { id: item.member_id } }">
        <v-btn
          color="primary"
          dark
          class="mr-2"
        >
          <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
          รายงานเติมเครดิต
        </v-btn>
      </router-link>

      <router-link
        class="none-decoration"
        :to="{ name: 'pages-report-add-cash',params: { id: item.member_id } }">
        <v-btn
          color="warning"
          dark
        >
          <v-icon
            small
            @click="deleteItem(item)"
          >
            {{ icons.mdiPencil }}
          </v-icon>
          รายงานเติมเงิน
        </v-btn>
      </router-link>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>
<style>
.none-decoration {
  text-decoration: none;
}
</style>
<script>
import {mdiPencil, mdiDelete} from '@mdi/js'
// import config from '../../../config'
import instance from '../../../axios.service'
import ModalAddCash from './ModalAddCash.vue'
import ModalAddCredit from './ModalAddCredit.vue'
import instance_credit_customer from '../../../services/creditCustomer'


export default {
  components: {
    ModalAddCash,
    ModalAddCredit
  },
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    serverOptions : {
      page: 1,
      itemsPerPage: 10,

    },
    total : 0,
    customer_id : '',
    credit_customers: [],
    dialogCash: false,
    dialogCredit: false,
    dialogDelete: false,
    search: '',
    sortBy: '',
    sortDesc: '',
    headers: [
      {
        text: 'ลำดับ',
        value: 'index',
        sortable: false
      },
      {text: 'ลูกค้า', value: 'member_name'},
      {text: 'ชื่อร้าน', value: 'member_shop_name'},
      {text: 'เบอร์ติดต่อ', value: 'member_tele'},
      // {
      //   text: 'หมายเลขลูกค้า',
      //   align: 'start',
      //   sortable: false,
      //   value: 'id',
      // },
      {text: 'เครื่องมือ', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
    defaultItem: {
      numberCustomer: '',
      status: '',
      numberTax: null,
      nameCustomer: null,
      credit: null,
      dateBill: '',
      dateGetMoney: '',
    },
  }),

  computed: {
    formCredit() {
      return this.editedIndex === -1 ? ' เติมเครดิต' : 'แก้ไข กลุ่มพนักงาน'
    },
    formCash() {
      return this.editedIndex === -1 ? ' เติมเงิน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    serverOptions : {
      handler(){
        this.getCreditCustomer()
      },
    },
    search : {
      handler(){
        this.getCreditCustomer()
      },
    },
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getCreditCustomer();
    // this.getUser()
  },

  methods: {
    getCreditCustomer() {
      this.$store.state.isLoading = true
      var data = {
        search : this.search,
        size : this.serverOptions.itemsPerPage,
        page : this.serverOptions.page,
        sortBy : this.sortBy ?? "",
        sortDesc : this.sortDesc ?? ''
      }
      instance_credit_customer.getCreditCustomer(data).then(res => {
        this.credit_customers = res.data.data.data
        this.total = res.data.data.total
        this.serverOptions.itemsPerPage = this.serverOptions.itemsPerPage == -1 ? -1 : res.data.data.per_page
        this.serverOptions.page = res.data.data.current_page
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false
      })
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = {...item}
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = {...item}
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {...this.defaultItem}
        this.editedIndex = -1
      })
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
